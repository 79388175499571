import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/render/project/src/src/components/blog-post-layout.js";
import Date from '../../components/Post/date';
import HeroPost from '../../components/Hero/hero-post';
import TwitterBox from '../../components/TwitterBox/twitter-box';
import PostsNavigation from '../../components/Post/posts-navigation';
import heroImg from '../../images/posts/008/throttle-vs-debounce.svg';
import leadingVsTrailingImg from '../../images/posts/008/leading-vs-trailing-edge.svg';
import styles from './008-throttle-vs-debounce-on-real-examples.module.css';
import Search from '../../components/PostExtras/TimingFunctions/search';
import AutosaveRichEditor from '../../components/PostExtras/TimingFunctions/autosave-rich-editor';
import Scroll from '../../components/PostExtras/TimingFunctions/scroll';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <HeroPost gradientFrom="#E28651" gradientTo="#A03632" mdxType="HeroPost">
  <img src={heroImg} alt="Flying clocks a meteors" height="248" />
    </HeroPost>
    <h1>{`Throttle vs Debounce on real examples`}</h1>
    <Date date="January 21, 2021" dateMachine="2021-01-21" mdxType="Date" />
    <p>{`What's the difference between throttle and debounce? Both are timing functions that limit the number of function calls so
finding a difference can be challenging. It is for me, more often than I'd like to admit. So I decided to write down a
couple of examples. `}<strong parentName="p">{`For which interactions we should use the throttle and for which debounce?`}</strong>{`
As a diligent student of User Interactions, I'm more interested in use cases than theory. By reading this post you'll
also learn `}<strong parentName="p">{`when not to use none of them`}</strong>{` because we have better APIs. `}<u>{`Interactive examples included!`}</u>{` 😍`}<br />{`
But first, let's start with a basic question:`}</p>
    <h2>{`What's the purpose of using these timing functions?`}</h2>
    <p>{`The ultimate reason is `}<strong parentName="p">{`the performance and protection of resources`}</strong>{`. That's it. The effect it has on UX is rarely the
thing that we wanted to do. We have to deal with the consequences of using these functions but if I could, I'd not use
them at all. But we don't have unlimited resources so we have to protect them.`}</p>
    <p>{`Throttle and Debounce `}<strong parentName="p">{`let you call a function fewer times than it would be usually called`}</strong>{` in a storm of events.
Fewer things to process simply mean better performance.`}</p>
    <h2>{`Throttle`}</h2>
    <p><strong parentName="p">{`Throttle runs a given function just once in a given period`}</strong>{`. Say you've got 400 events in 2 seconds but you've decided
to throttle that stream and let it be executed just once per second. As a result, your function will be called just twice.
Instead of 400!`}</p>
    <p>{`From a user's point of view, their action will be run every 1 second. No matter how many actions in-between they do.`}</p>
    <h2>{`Debounce`}</h2>
    <p>{`Debounce on the other hand will `}<strong parentName="p">{`run a given function after a given period of time will pass without a single event`}</strong>{`.
To put it to the extreme - if you have a constant stream of events that happen every 1 second, and you decided to debounce
a function to be run after 2 seconds of "no action" then your function will be never executed.`}</p>
    <p>{`From a user's point of view, they have to stop doing something for 2 seconds to see the result of their action.`}</p>
    <h2>{`When we should use one or another?`}</h2>
    <p><strong parentName="p">{`Maybe instead of me saying what I think`}</strong>{` we could take a look at some real-life examples with three cases:`}</p>
    <ul>
      <li parentName="ul">{`no timing function`}</li>
      <li parentName="ul">{`throttle`}</li>
      <li parentName="ul">{`debounce`}</li>
    </ul>
    <p>{`and we will get to the answer together by looking at a number of events handled? and the general look&feel?`}</p>
    <h3>{`Searching or typeahead`}</h3>
    <p>{`In functionality like searching or getting hints (typeahead) in most cases, we have to call the server.
This kind of functionality happens on every keystroke - not by clicking on the "Search" button. So it will naturally
create a lot of events.`}</p>
    <p>{`We have to protect the server and reduce the number of calls. `}<strong parentName="p">{`Play with the input below`}</strong>{`. Just for the sake of simplicity,
this search will always show a loading state and "No results".`}<br />{`Observe the number of API calls:`}</p>
    <Search mdxType="Search" />
    <p>{`Did you notice that? Debounce seems to be the best in protecting the resources. `}<strong parentName="p">{`It waits until a user stops typing.`}</strong>{`
I know everyone is referring to this example as the perfect use of `}<inlineCode parentName="p">{`debounce`}</inlineCode>{` but that's not perfect. It will make your
search feel a bit sluggish. No matter how fast your API is, it will always add that extra delay until the API call will
be made (here 400ms). There is a better way for typeahead but I'll write about it in another post. Here let's focus on
timing functions. If you need to aggressively protect the resources I say `}<strong parentName="p">{`debounce is good enough`}</strong>{`.`}</p>
    <h3>{`Save as a user writes`}</h3>
    <p>{`So is debounce the best for typing activities? No. Not always. Imagine you have a rich text editor where a user types something
and we perform autosave in the background. `}<strong parentName="p">{`Debounce`}</strong>{`'s characteristic to wait until the stream of events has stopped for a while
`}<strong parentName="p">{`can be dangerous here`}</strong>{`.`}</p>
    <p>{`We can imagine that an impatient user types something and leaves the page before the actual save was performed. With autosaving,
we have to use every occasion to store the state (but still protecting our resources).`}</p>
    <p><strong parentName="p">{`Play with the textarea below`}</strong>{` and observe what were the saving states. The server you use can also keep these intermediate
states as points in history to offer a simple revision functionality.`}</p>
    <AutosaveRichEditor mdxType="AutosaveRichEditor" />
    <p><strong parentName="p">{`Hover on the circles`}</strong>{` that represent the saved state (they will appear after typing). Using `}<inlineCode parentName="p">{`throttle`}</inlineCode>{` seems to be a
better idea here. We do want to protect the server (especially here!) but we don't want to lose any of the user's work either.
Timing functions are set to something long (like 2-3 seconds) to not produce too many saves but that harms `}<inlineCode parentName="p">{`debounce`}</inlineCode>{`.
It is rarely called and doesn't give us "checkpoints" when typing.`}</p>
    <p>{`It's not yet ideal but a very good start. To make it better you should learn about canceling requests and leading or trailing
edge of a timing function. We'll get back to it. But for now, I say `}<strong parentName="p">{`throttle is the way to go for autosaving`}</strong>{`.`}</p>
    <h3>{`Scrolling interactions`}</h3>
    <p>{`One of the most common examples in timing functions is limiting the number of scroll events. Let's look at how many
events scroll can generate:`}</p>
    <Scroll mdxType="Scroll" />
    <p>{`Okay, so it seems we should do something about it. Scroll naturally generates a massive amount of events. So `}<strong parentName="p">{`what kind of interactions
we should consider limiting?`}</strong>{` I know there are examples of using throttle or debounce to calculate the scroll position to
implement an infinite scroll. `}<strong parentName="p">{`That's wrong.`}</strong>{` You should use Intersection Observer for that. Period.`}</p>
    <p>{`But we can imagine you'd like to show the progress of scroll a.k.a "how much did user read" functionality. Let's see how that
works with three approaches (events count is in the brackets):`}</p>
    <Scroll reading={true} mdxType="Scroll" />
    <p>{`So we are updating the style of progress bars depending on the scroll position. It's obvious that timing functions introduce some
kind of lag here. `}<inlineCode parentName="p">{`debounce`}</inlineCode>{` here is something hard to consider unless you really need to protect resources but why would you
implement such a feature then?`}</p>
    <p><inlineCode parentName="p">{`throttle`}</inlineCode>{` is a bit better to reduce the number of events and still looking quite ok. But to have it really "smooth" we would have
to go down to `}<inlineCode parentName="p">{`16ms`}</inlineCode>{` of delay. Why 16ms? Because that often you have to refresh an animation to keep `}<inlineCode parentName="p">{`60FPS`}</inlineCode>{` (1000ms / 60 frames).
There is a better API for that and is called `}<inlineCode parentName="p">{`requestAnimationFrame`}</inlineCode>{`.`}</p>
    <p><strong parentName="p">{`Play with scroll below.`}</strong>{` Observe how smooth the animation is and how often it was refreshed.`}</p>
    <Scroll reading={true} requestAnim={true} mdxType="Scroll" />
    <p>{`Boom 💥 If you are on a fast computer (MacBook?) you won't even notice a difference between "no timing function", "requestAnimationFrame"
and "throttled run every 16ms". `}<strong parentName="p">{`But trust me`}</strong>{`, use `}<inlineCode parentName="p">{`rAF`}</inlineCode>{` in case you animate something based on scroll or mouse move.`}</p>
    <p>{`Here is a screenshot of one of the states I landed in:`}</p>
    <figure {...{
      "className": "gatsby-resp-image-figure",
      "style": {}
    }}>{`
    `}<span parentName="figure" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "960px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/0fb791b374e2efe5526d99bff84dc517/24def/rAF-vs-throttle-16ms.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "26.25%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAFCAIAAADKYVtkAAAACXBIWXMAAAsSAAALEgHS3X78AAAA70lEQVQY02P4DwPvz194vnXb8507n+/Y8XHfvq8HD37YuxfIQKD9+z/s2vXt1i2Q6n//gAQDnPVgyYrzReVXa5tv1rWeLKk8W1b9or3nSWvX05bOp0ASiNq7n9Q1v925G4vm62t376+ftrWmZ3N56576CRur+y71L7o0YTEYLbzUv/DyxCUXOuY93ncKTTOI2LHu9tTWM9M7T01pOz6x+dDk1qPT+/bMmLhr5qTd0yZsmTFx28zJOye17z+8+yFML5LmQ7vuLpp2btXcSyvngNHsi8tnnV8x+9zy2eeA5Io551fMObdo6pmzxx7BNQMA+dvq8bA/JAgAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Missing frames in throttle 16ms",
            "title": "Missing frames in throttle 16ms",
            "src": "/static/0fb791b374e2efe5526d99bff84dc517/d9199/rAF-vs-throttle-16ms.png",
            "srcSet": ["/static/0fb791b374e2efe5526d99bff84dc517/8ff5a/rAF-vs-throttle-16ms.png 240w", "/static/0fb791b374e2efe5526d99bff84dc517/e85cb/rAF-vs-throttle-16ms.png 480w", "/static/0fb791b374e2efe5526d99bff84dc517/d9199/rAF-vs-throttle-16ms.png 960w", "/static/0fb791b374e2efe5526d99bff84dc517/07a9c/rAF-vs-throttle-16ms.png 1440w", "/static/0fb791b374e2efe5526d99bff84dc517/24def/rAF-vs-throttle-16ms.png 1812w"],
            "sizes": "(max-width: 960px) 100vw, 960px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
        "className": "gatsby-resp-image-figcaption"
      }}>{`Missing frames in throttle 16ms`}</figcaption>{`
  `}</figure>
    <p>{`On my super-fast computer, the difference between `}<inlineCode parentName="p">{`throttle`}</inlineCode>{` run every 16ms and `}<inlineCode parentName="p">{`requestAnimationFrame`}</inlineCode>{` was super small
and you'd not notice the missing frames in the animation. It can make a difference on slower devices.`}</p>
    <h2>{`Leading and trailing edge`}</h2>
    <p>{`So far, the examples above were using the trailing edge. `}<strong parentName="p">{`The edge settings tell if the function should be run at the beginning
of the stream of events or the end.`}</strong></p>
    <div className={['container', styles.graph].join(' ')}>
  <img src={leadingVsTrailingImg} alt="Leading vs trailing edge based no throttle example" width={547} height={384} />
    </div>
    <p>{`For throttle, the case is easy when picking `}<em parentName="p">{`leading-edge`}</em>{` - you want your function to be run immediately after the
stream of events occurs and then every 1s for example.`}</p>
    <p>{`Debounce on the other hand changes its characteristic. `}<strong parentName="p">{`The mental model for running debounce immediately is to think about it as a mute`}</strong>{`.
You run something once and ignore all other events until there is a time window (say 1s) of no events. If the events happen
after that we are back to running it only once at the beginning.`}</p>
    <h2>{`Implementation`}</h2>
    <p>{`How to implement debounce and throttle? `}<strong parentName="p">{`By importing it from lodash for example`}</strong>{` 😛 Really. There is no need to
re-implement the wheel. `}<a parentName="p" {...{
        "href": "https://lodash.com/docs/4.17.15#debounce"
      }}>{`Their implementation`}</a>{` is great and has additional features
that I didn't cover here. Like `}<inlineCode parentName="p">{`maxWait`}</inlineCode>{`. A super-powerful feature that will let you call a function if it didn't happen
for some time (emergency call!). Really great to fight debounce's slugginess.`}</p>
    <h2>{`Summary`}</h2>
    <p>{`The timing functions are great. But for some interactions, we have better APIs these days. Sometimes you might not even see the UI affected,
and `}<strong parentName="p">{`that's generally what we want`}</strong>{`.`}</p>
    <p>{`Protection of resources is important but remember - `}<strong parentName="p">{`there is a user at the end`}</strong>{`. The last thing we want is to screw up their
experience.`}</p>
    <p>{`By adjusting timings you can `}<em parentName="p">{`eat a cake and have a cake`}</em>{`. Bon appetit! 🍰`}</p>
    <div className="container mt-40 mb-55">
  <TwitterBox threadLink="https://twitter.com/tomekdev_/status/1352245710419329035" mdxType="TwitterBox" />
    </div>
    <PostsNavigation prevTitle="Highlight text in JavaScript" prevLink="/posts/highlight-text-in-javascript" nextTitle="Anchors for headings in MDX" nextLink="/posts/anchors-for-headings-in-mdx" mdxType="PostsNavigation" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      